import { ref } from "vue"
import { UUID } from "@avvoka/shared"

export const getUUID = () => {
  /**
   * Lazy fix for crypto#randomUUID not available for localhost when aliased
   */
  if (import.meta.env.PROD) {
    return window.crypto.randomUUID()
  } else {
    return UUID.new()
  }
}

export function useElementId (id: string | undefined) {
  return ref(id || `el-${getUUID()}`)
}
